import React from 'react';
import { PortfolioPage } from '../../modules/PortfolioPage';
import { listItems } from '../../utils/data/projectList';
import { VCSectionBlock } from '../../globalComponents/VCSectionBlock';
import challengeImage from '../../images/projects/RX/challenge.jpg';
import challengeImagew from '../../images/projects/RX/challenge.webp';

// markup
const ProjectPage = () => {
  const projectData = listItems[2];
  const totalBlocks = 1;

  return (
    <main>
      <PortfolioPage metaData={projectData}>
        <VCSectionBlock
          title="The site"
          sectionNumber={1}
          sectionTotal={totalBlocks}
          image={{
            jpeg: challengeImage,
            webp: challengeImagew,
            alt: `The first fold of the page.`,
          }}
        >
          <p>
            RX Watches was a demo for a shopping site that I was working on. The
            focus was a minimalist menu and navigation, while also having a
            pop-up design meaning that the site plays a lot with the depth of
            the elements going behind and on top of others without interrupting
            the flow of the experience. <br />
            The webpage does not have any sort of backend service implementation
            so pages like the checkout are just static. The project is open
            source for everyone that wants to use it, it is develpoped in
            vanilla HTML, CSS and JS.
          </p>
          <br />
          <a
            href="https://virtualclover.com.mx/web/RX/hosted/index.html"
            target="_blank"
          >
            Check the coded site!
          </a>
        </VCSectionBlock>
      </PortfolioPage>
    </main>
  );
};

export default ProjectPage;
